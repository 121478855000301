import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import React, { useContext } from "react";

import { Link } from "react-router-dom";
import ModalContext from "../utils/ModalContext";
import { sikamaLogo } from "../utils/images";

function AppFooter() {
  const { setToggleModal } = useContext(ModalContext);
  return (
    <footer className="sectionPadding">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="footerLeft">
              <img alt="" src={sikamaLogo} />
              <p className="mb-0">
                <strong>
                  Sikama Financial Services, LLC 202 North Ceder Ave., Suite 1
                  Owatonna, MN 55060 United States.
                </strong>
              </p>
              <p>
                Sikama Financial Services, a Fintech company, was established to
                revolutionize how people transfer funds by creating innovative
                technology solutions.
              </p>
              <ul className="socialIcons">
                <li>
                  <Link to="https://www.facebook.com/SikamaApp" target="_blank">
                    <FaFacebookF />
                  </Link>
                </li>
                <li>
                  <Link to="https://twitter.com/SikamaApp" target="_blank">
                    <FaTwitter />
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.instagram.com/SikamaApp"
                    target="_blank"
                  >
                    <FaInstagram />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-8">
            <div className="linkeContainer">
              <h4>Company</h4>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/blogs">Blogs</Link>
                </li>
                <li>
                  <Link onClick={() => setToggleModal(true)}>Sign up</Link>
                </li>
              </ul>
            </div>
            <div className="linkeContainer">
              <h4>Support</h4>
              <ul>
                <li>
                  <Link to="/terms_conditions">Terms</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
                <li>
                  <Link to="/privacy_policy">Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <p className="copywrite">
        © Sikama Financial Services {new Date().getFullYear()}
      </p>
    </footer>
  );
}

export default AppFooter;
